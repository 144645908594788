import React from "react";
import { useLocation } from "react-router-dom";

import LogoTum from "../Images/logo_tum.png";

import {
  Navbar,
  Nav,
  // NavDropdown,
  //   Dropdown,
  // Badge,
  // Modal,
  //   Container,
  //   NavItem,
} from "react-bootstrap";

function Menu() {
  const location = useLocation();
  const curBrwloc = location["pathname"];
  //   var curBrw2 = curBrwloc.split("/")[2];
  var active_Who_we_are = "";
  var active_projects = "";
  var active_services = "";
  var active_contact = "";

  {
    curBrwloc === "/projects" && (active_projects = "active");
    curBrwloc === "/who_we_are" && (active_Who_we_are = "active");
  }
  return (
    <Navbar
      fixed="top"
      collapseOnSelect
      expand="lg"
      variant="info"
      // className="navbar"
      // sticky="top"
      className="shadow"
    >
      <Navbar.Brand href="/">
        <img src={LogoTum} alt="Logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          {/* <Nav.Link
            className="nav-links"
            href="about"
            active={active_Who_we_are}
          >
            Who_we_are
          </Nav.Link>
          <Nav.Link
            className="nav-links"
            href="projects"
            active={active_projects}
          >
            Projects
          </Nav.Link>
          <Nav.Link
            className="nav-links"
            href="services"
            active={active_services}
          >
            Services
          </Nav.Link>*/}
          {/* <Nav.Link className="" width="100%">
            &nbsp;
          </Nav.Link> */}
        </Nav>
        <Nav align="end">
          <Nav.Link
            align="end"
            className="nav-links"
            href="#contact"
            // active={active_contact}
          >
            Contact us
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Menu;
