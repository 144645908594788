import React from "react";
import { useLocation } from "react-router-dom";

import Menu from "../Pages/Menu";
import Home from "../Pages/Home";
import Projects from "../Pages/Internet";

function Website() {
  const location = useLocation();
  const curBrwloc = location["pathname"];
  // var curBrw2 = curBrwloc.split("/")[2];

  return (
    <div>
      <Menu />
      {curBrwloc === "/" && <Home />}
      {curBrwloc === "/projects" && <Projects />}
      {curBrwloc === "/who_we_are" && <Projects />}
    </div>
  );
}

export default Website;
