import React from "react";
import { MdOutlineScreenSearchDesktop } from "react-icons/md";
// MdOutlineScreenSearchDesktop

function Nopage() {
  return (
    <div className="cs_center">
      <div className="h-100 d-flex align-items-center justify-content-center">
        <MdOutlineScreenSearchDesktop fontSize={100} color={"white"} />
        <h1 style={{ color: "white" }}>Page Not Found</h1>
      </div>
    </div>
  );
}

export default Nopage;
