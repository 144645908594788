import React from "react";
import { Routes, Route } from "react-router-dom";

import Internet from "./Pages/Internet";
import Nopage from "./Pages/Nopage";
import Website from "./Pages/Website";

function App() {
  return (
    <Routes>
      <Route path="*" exact={true} element={<Nopage />} />
      {!navigator.onLine && <Internet />}
      <Route exact path="/" element={<Website />} />
      <Route exact path="/Projects" element={<Website />} />
    </Routes>
  );
}

export default App;
