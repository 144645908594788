import React from "react";
import Flip from "react-reveal/Flip";

import H_img1 from "../Images/h1.jpg";
import H_img2 from "../Images/h2.jpg";
import H_img4 from "../Images/h4.jpg";
import H_img5 from "../Images/h5.jpg";

import {
  Carousel,
  // Nav,
  //   NavDropdown,
  //   Dropdown,
  // Badge,
  // Modal,
  Container,
  //   NavItem,
} from "react-bootstrap";

function Home() {
  return (
    <div>
      <Carousel>
        <Carousel.Item>
          <img className="d-block w-100" src={H_img1} alt="Every where." />
          <Flip left>
            <div className="cs_slider_text">
              PATVAD CAPITAL INVESTMENT
              <p>
                We give businesses the money they need to achieve their goals.
                We provide a full range of investment products and services
                including loans for local workers. Our investment are tailored
                to individual clients.
              </p>
            </div>
          </Flip>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={H_img2}
            alt="Estate management."
          />
          <Flip left>
            <div className="cs_slider_text">
              ESTATE MANAGEMENT
              <p>
                Our estate management includes a broad category of commercial
                properties such as offices, hotels and shops. We oversee all
                maintenance and repairs to the estate grounds and buildings,
                manage house staffs and groundskeepers and coordinate event and
                functions. We are into managing lands and buildings, including
                activities such as keeping buildings in good condition and
                organizing the renting of property.
              </p>
            </div>
          </Flip>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={H_img4} alt="MINING" />
          <Flip left>
            <div className="cs_slider_text">
              MINING
              <p>
                The extraction of valuable minerals or other geological
                materials from the earth. We are into two types of mining, the
                and deep mining. Our mining sites is located in Sunyani. It is
                about 300 acres of lands. We are into processing, sale or
                transporting of coal and coal by-products of services.{" "}
              </p>
            </div>
          </Flip>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={H_img5} alt="Every where." />
          <Flip left>
            <div className="cs_slider_text">
              PROJECT FINANCE
              <p>
                We are into funding (financing) of long-term infrastructure,
                industrial projects and public services and projects with cash
                flows and assets.
              </p>
            </div>
          </Flip>
        </Carousel.Item>
      </Carousel>
      <div style={{ background: "black" }}>
        <Container>
          <div id="contact" className="d-flex justify-content-between p-5">
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3971.154084727242!2d-0.3820593113445846!3d5.544158286403413!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdfbb5cf7c814dd%3A0x737138ce559333a1!2sPatvad%20Consult%20Limited!5e0!3m2!1sen!2sgh!4v1662385576242!5m2!1sen!2sgh"
                width={600}
                height={450}
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
            <div style={{ color: "white" }}>
              <h3>
                <b>Office Line</b>
              </h3>
              <p>0243987791</p>
              <p>0242980099</p>

              <p>
                <h3>
                  <b>Office Locations</b>
                </h3>
              </p>
              <p>Laboni close to the coffee shop</p>
              <p>Ajasco junction kokrobite</p>
              <p>
                <h3>
                  <b>E-mail</b>
                </h3>
              </p>
              <p>info@patvad.com</p>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default Home;
