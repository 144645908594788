import React from "react";
import { MdSignalWifiStatusbarConnectedNoInternet } from "react-icons/md";

function Internet() {
  setTimeout(function () {
    window.location.reload(1);
  }, 5000);

  return (
    <div className="cs_center">
      <div className="h-100 d-flex align-items-center justify-content-center">
        <MdSignalWifiStatusbarConnectedNoInternet
          fontSize={100}
          color={"white"}
        />
        <h1 style={{ color: "white" }}>no internet</h1>
      </div>
    </div>
  );
}

export default Internet;
